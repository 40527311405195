
try {
  // console.log('globalThis before', (globalThis as any)['isMobile']);
  (globalThis as any)['isMobile'] = window.innerWidth <= 768;
  console.log('globalThis after', (globalThis as any)['isMobile']);
} catch (error) {
  // (globalThis as any)['isMobile'] = true;
  console.log('check server is mobile failed, use default:', (globalThis as any)['isMobile']);
}
export const domainEnvironment = {
  timestamp: '20:11:30 10/5/2024',
  appVersion: '3.6.0',
  afterLoginUrl: '/',
  domain: 'reader',
};
